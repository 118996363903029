var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-icon", class: { small: _vm.size } },
    [
      _c("div", { staticClass: "profile-icon-circle" }, [
        _c("div", [_vm._v(_vm._s(_vm.firstName) + _vm._s(_vm.lastName))])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }