<template>
  <div class="profile-icon" :class="{ small: size }">
    <div class="profile-icon-circle">
      <div>{{ firstName }}{{ lastName }}</div>
    </div>
  </div>
</template>

<script>
import authentication from "../../authentication";

export default {
  props: {
    size: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    firstName() {
      if (!authentication.isAuthenticated) {
        return null;
      }
      const f = authentication.getUserFirstname();
      return f.charAt(0) ? f.charAt(0) : '' ;
    },
    lastName() {
      if (!authentication.isAuthenticated) {
        return null;
      }
      const l = authentication.getUserLastname();
      return l.charAt(0) ? l.charAt(0) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-icon {
  width: 230px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  .profile-icon-circle {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #1487c0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    margin: 5px;
    justify-content: space-around;
    div {
      font-size: 40px;
      text-transform: uppercase;
      margin-top: -5px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      justify-content: space-around;
      margin-left: 3px;
    }
  }
}
.small {
  width: 30px;
  .profile-icon-circle {
    height: 20px;
    width: 20px;
    background-color: #fff !important;
    color: #1487c0;
    font-weight: 600;
    div {
      font-size: 10px !important;
      margin-top: -1px;
      margin-left: 1px;
    }
  }
}
</style>
